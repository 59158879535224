import { AuthService, PasswordValidation } from '@geovelo-frontends/commons';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { AppContext } from '../../app/context';
import { VisibilityIcon, VisibilityOffIcon } from '../../components/icons';
import { environment } from '../../environment';

interface IFormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
  approveEventGCU: boolean;
  approveGCU: boolean;
  approvePrivacyPolicy: boolean;
}

function SignUp({ loading }: { loading: boolean }) {
  const {
    event: { config },
  } = useContext(AppContext);
  const [passwordShowed, showPassword] = useState(false);
  const {
    actions: { setCurrentUser, setGeogroups, setRegisteredGroups, setPartners },
  } = useContext(AppContext);
  const {
    isSubmitting,
    values,
    touched,
    errors,
    isValid,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik<IFormValues>({
    initialValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
      approveEventGCU: false,
      approveGCU: false,
      approvePrivacyPolicy: false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("L'email est requis"),
      password: Yup.string()
        .required(
          'Doit contenir au minimum 8 caractères dont un chiffre, une minuscule, une majuscule et un caractère spécial.',
        )
        .matches(
          /^(?=.*[\d])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/g,
          'Doit contenir au minimum 8 caractères dont un chiffre, une minuscule, une majuscule et un caractère spécial.',
        ),
      passwordConfirmation: Yup.string().when('password', {
        is: (password: string) => password,
        then: (shema) =>
          shema
            .oneOf([Yup.ref('password')], 'Les mots de passe sont différents')
            .required('Les mots de passe sont différents'),
      }),
      approveEventGCU: Yup.boolean().oneOf([true]),
      approveGCU: Yup.boolean().oneOf([true]),
      approvePrivacyPolicy: Yup.boolean().oneOf([true]),
    }),
    enableReinitialize: true,
    onSubmit,
  });
  const snackbar = useSnackbar();

  useEffect(() => {
    setSubmitting(loading);
  }, [loading]);

  async function onSubmit(
    { email, password }: IFormValues,
    { setSubmitting, setErrors }: FormikHelpers<IFormValues>,
  ) {
    setSubmitting(true);

    try {
      const user = await AuthService.register(email, password, 'fr');

      setCurrentUser(user);
      setGeogroups([]);
      setRegisteredGroups([]);
      setPartners([]);
    } catch (err) {
      if (err && typeof err === 'object' && (err as { code?: number }).code === 409) {
        setErrors({ email: 'Il existe déjà un compte avec cet email' });
      } else {
        snackbar?.enqueueSnackbar("Le compte n'a pas pu être créé");
      }
      setSubmitting(false);
    }
  }
  return (
    <>
      <Box component="form" display="flex" flexDirection="column" gap={2} onSubmit={handleSubmit}>
        <StyledTextField
          disabled={isSubmitting}
          error={Boolean(errors.email && touched.email)}
          helperText={touched.email && errors.email}
          id="email"
          margin="none"
          name="email"
          onChange={handleChange}
          placeholder="Email"
          size="small"
          type="email"
          value={values.email}
          variant="outlined"
        />
        <StyledTextField
          disabled={isSubmitting}
          error={touched.password && Boolean(errors.password)}
          id="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => showPassword(!passwordShowed)}
                  size="small"
                >
                  {passwordShowed ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          margin="none"
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Mot de passe"
          size="small"
          type={passwordShowed ? 'text' : 'password'}
          value={values.password}
          variant="outlined"
        />
        <PasswordValidation password={values.password} />
        <StyledTextField
          disabled={isSubmitting}
          error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
          helperText={touched.passwordConfirmation && errors.passwordConfirmation}
          id="passwordConfirmation"
          margin="none"
          name="passwordConfirmation"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Confirmation du mot de passe"
          size="small"
          type="password"
          value={values.passwordConfirmation}
          variant="outlined"
        />
        <Box display="flex" flexDirection="column">
          <FormControl component="fieldset" disabled={isSubmitting} margin="none">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="approveEventGCU"
                  onChange={handleChange}
                  style={
                    errors.approveEventGCU && touched.approveEventGCU ? { color: red[500] } : {}
                  }
                  value={values.approveEventGCU}
                />
              }
              label={
                <Typography variant="caption">
                  J'accepte les <Link to="/cgu">CGU</Link> du site Challenge{' '}
                  {config === 'mdv' ? 'Mois du Vélo' : 'Mai à Vélo'}
                </Typography>
              }
            />
          </FormControl>
          <FormControl component="fieldset" disabled={isSubmitting} margin="none">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="approveGCU"
                  onChange={handleChange}
                  style={errors.approveGCU && touched.approveGCU ? { color: red[500] } : {}}
                  value={values.approveGCU}
                />
              }
              label={
                <Typography variant="caption">
                  J'ai lu et j'accepte les{' '}
                  <Typography
                    color="primary"
                    component={Link}
                    rel="noreferrer"
                    target="_blank"
                    to={`${environment.frontendUrl}/fr/eula`}
                    variant="caption"
                  >
                    Conditions Générales d'Utilisation
                  </Typography>
                </Typography>
              }
            />
          </FormControl>
          <FormControl component="fieldset" disabled={isSubmitting} margin="none">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="approvePrivacyPolicy"
                  onChange={handleChange}
                  style={
                    errors.approvePrivacyPolicy && touched.approvePrivacyPolicy
                      ? { color: red[500] }
                      : {}
                  }
                  value={values.approvePrivacyPolicy}
                />
              }
              label={
                <Typography variant="caption">
                  J'ai lu et j'accepte la{' '}
                  <Typography
                    color="primary"
                    component={Link}
                    rel="noreferrer"
                    target="_blank"
                    to={`${environment.frontendUrl}/fr/privacy-policy`}
                    variant="caption"
                  >
                    Politique de Confidentialité
                  </Typography>
                </Typography>
              }
            />
          </FormControl>
        </Box>
        <Button
          color="primary"
          disabled={isSubmitting || !isValid || !values.email}
          size="medium"
          type="submit"
          variant="contained"
        >
          S'inscrire
        </Button>
      </Box>
    </>
  );
}

const StyledTextField = styled(TextField)`
  &&& {
    .MuiInputBase-root {
      &::before,
      &::after {
        border-bottom: 0 !important;
      }

      border-radius: 8px;

      input {
        padding-bottom: 12px;
        padding-top: 12px;
      }
    }
  }
`;

export default SignUp;
