import { AuthService, GeogroupService, UserService } from '@geovelo-frontends/commons';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { AppContext } from '../../app/context';
import { VisibilityIcon, VisibilityOffIcon } from '../../components/icons';
import { environment } from '../../environment';

interface IValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

function SignIn({ loading }: { loading: boolean }) {
  const [passwordShowed, showPassword] = useState(false);
  const {
    event: { config },
    actions: { setCurrentUser, setGeogroups, setPartners, setRegisteredGroups },
  } = useContext(AppContext);
  const {
    isSubmitting,
    values,
    touched,
    errors,
    setFieldValue,
    setSubmitting,
    isValid,
    handleChange,
    handleSubmit,
  } = useFormik<IValues>({
    initialValues: { email: '', password: '', rememberMe: false },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("L'email est invalide").required("L'email est requis"),
      password: Yup.string().required(),
    }),
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    setSubmitting(loading);
  }, [loading]);

  async function onSubmit(
    { email, password, rememberMe }: IValues,
    { setSubmitting, setErrors }: FormikHelpers<IValues>,
  ) {
    if (!email || !password) return;

    setSubmitting(true);

    try {
      const user = await AuthService.signInWithEmail(email, password, rememberMe, 'fr');

      const geogroups = await GeogroupService.getUserGeogroups();

      const partners = await UserService.getPartners({
        isAdmin: user?.isGeovelo,
        isSupport: user?.isSupport,
      });

      const userChallenges = await UserService.getUserChallenges({
        page: 1,
        pageSize: 100,
        query: '{challenge{target_type, group}}',
        eventId: config === 'mdv' ? environment.mdvEventId : environment.eventId,
      });

      const registeredGroups: number[] = [];
      userChallenges.forEach(({ challenge: { group } }) => !!group && registeredGroups.push(group));

      try {
        localStorage.setItem('type', 'user');
      } catch {
        console.error('localStorage access is denied');
      }

      setCurrentUser(user);
      setGeogroups(
        geogroups.filter(
          ({ group: { id, type }, isAdmin }) =>
            type !== 'city' || isAdmin || partners?.find(({ geoGroupId }) => geoGroupId === id),
        ) || [],
      );
      setRegisteredGroups(registeredGroups);
      setPartners(partners);
    } catch (err) {
      console.error(err);
      setErrors({
        email:
          typeof err === 'object' && err && 'code' in err && err.code === 429
            ? 'Trop de tentatives, veuillez réessayer plus tard.'
            : 'Identifiants invalides',
      });
      setFieldValue('password', '', false);
      setSubmitting(false);
    }
  }

  return (
    <>
      <Box component="form" display="flex" flexDirection="column" gap={2} onSubmit={handleSubmit}>
        <StyledTextField
          disabled={isSubmitting}
          error={Boolean(errors.email && touched.email)}
          helperText={touched.email && errors.email}
          id="email"
          margin="none"
          name="email"
          onChange={handleChange}
          placeholder="Email"
          size="small"
          type="email"
          value={values.email}
          variant="outlined"
        />
        <StyledTextField
          disabled={isSubmitting}
          id="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => showPassword(!passwordShowed)}
                  size="small"
                >
                  {passwordShowed ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          margin="none"
          name="password"
          onChange={handleChange}
          placeholder="Mot de passe"
          size="small"
          type={passwordShowed ? 'text' : 'password'}
          value={values.password}
          variant="outlined"
        />
        <FormControl component="fieldset" disabled={isSubmitting} margin="none">
          <FormControlLabel
            disableTypography
            control={
              <Checkbox
                color="primary"
                name="rememberMe"
                onChange={handleChange}
                value={values.rememberMe}
              />
            }
            label={<Typography variant="caption">Se souvenir de moi</Typography>}
          />
        </FormControl>
        <Button
          color="primary"
          disabled={isSubmitting || !isValid || !values.email}
          size="large"
          sx={{ textTransform: 'initial' }}
          type="submit"
          variant="contained"
        >
          Se connecter
        </Button>
      </Box>
      <Box display="flex" flexDirection="row">
        <Typography
          color="primary"
          component={Link}
          to={`${environment.frontendUrl}/fr/forgot-password`}
          variant="caption"
        >
          Mot de passe oublié ?
        </Typography>
      </Box>
    </>
  );
}

const StyledTextField = styled(TextField)`
  &&& {
    .MuiInputBase-root {
      &::before,
      &::after {
        border-bottom: 0 !important;
      }
      border-radius: 8px;

      input {
        padding-bottom: 12px;
        padding-top: 12px;
        border-radius: 8px;
      }
    }
  }
`;

export default SignIn;
