import 'moment/locale/fr';
import {
  AppService,
  CyclabilityZone,
  CyclabilityZoneService,
  Event,
  EventService,
  GeogroupService,
  Partner,
  User,
  UserGeogroup,
  UserService,
  i18nCommons,
} from '@geovelo-frontends/commons';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { HelmetProvider, HelmetServerState } from 'react-helmet-async';

import { environment } from '../environment';

import { AppContext } from './context';
import matomoInstance from './matomo';
import Router from './router';
import theme from './theme';

function getZoneCode({ name }: { name: string }) {
  return name
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-')
    .replace(/\'/g, '-');
}

const helmetContext = {} as {
  helmet: HelmetServerState;
};

function App(): JSX.Element {
  const [event, setEvent] = useState<Event>();
  const [config, setConfig] = useState<string | undefined>(process.env.REACT_APP_CONFIG);

  const [currentUser, setCurrentUser] = useState<User | null>();
  const [userGeogroups, setUserGeogroups] = useState<UserGeogroup[]>();
  const [userPartners, setUserPartners] = useState<Partner[]>();
  const [registeredGroups, setRegisteredGroups] = useState<number[]>();

  const [zonesMap, setZonesMap] = useState<{ [id: number]: { id: number; name: string } }>();
  const [france, setFrance] = useState<CyclabilityZone>();
  const [regions, setRegions] = useState<CyclabilityZone[]>();
  const [epcisMap, setEPCIsMap] = useState<{ [regionId: number]: CyclabilityZone[] }>();
  const [zonesCodesMap, setZonesCodesMap] = useState<{ [id: number]: string }>();
  const [initialized, setInitialized] = useState(false);
  const loadingZones = useRef(false);

  useEffect(() => {
    AppService.environment = environment;
    i18nCommons.changeLanguage('fr');
    moment.locale('fr');

    getEvent();
    getCurrentUser();
    setInitialized(true);
  }, []);

  async function getEvent() {
    try {
      const event = await EventService.getEvent(
        config === 'mdv' ? environment.mdvEventId : environment.eventId,
      );

      setEvent(event);
    } catch (err) {
      console.error(err);
    }
  }

  async function getCurrentUser() {
    try {
      const user = await UserService.getCurrentUser();
      if (!user) {
        setCurrentUser(null);
        return;
      }

      const geogroups = await GeogroupService.getUserGeogroups();
      const partners = await UserService.getPartners({
        isAdmin: user?.isGeovelo,
        isSupport: user?.isSupport,
      });
      await getRegisteredGroups();

      setCurrentUser(user);
      setUserGeogroups(
        geogroups.filter(
          ({ group: { id, type, code }, isAdmin }) =>
            (type !== 'city' || isAdmin || partners?.find(({ geoGroupId }) => geoGroupId === id)) &&
            code !== 'france',
        ) || [],
      );
      setUserPartners(partners);
    } catch (err) {
      console.error(err);
    }
  }

  async function getZones() {
    if (loadingZones.current) return;

    loadingZones.current = true;

    try {
      const [
        {
          zones: [france],
        },
        { zones: regions },
        eventZones,
      ] = await Promise.all([
        CyclabilityZoneService.getZones({
          administrativeLevel: 'COUNTRY',
          countryCode: 'fr',
          query: '{id,name,administrative_level}',
        }),
        CyclabilityZoneService.getZones({
          administrativeLevel: 'REGION',
          query: '{id,name,administrative_level,country_code}',
          rowsPerPage: 50,
        }),
        EventService.getCyclabilityZones(
          config === 'mdv' ? environment.mdvEventId : environment.eventId,
        ),
      ]);

      setFrance(france);
      setRegions(
        regions
          .filter(({ countryCode }) => countryCode && ['fr', 're'].includes(countryCode))
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
      );
      setEPCIsMap(
        eventZones.reduce<{ [regionId: number]: CyclabilityZone[] }>(
          (res, { regionId, children }) => {
            res[regionId] = children
              .map(({ id, name }) => new CyclabilityZone(id, 'epci', undefined, undefined, name))
              .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            return res;
          },
          {},
        ),
      );
      setZonesCodesMap({
        [france.id]: getZoneCode(france),
        ...regions.reduce<{ [id: number]: string }>((res, zone) => {
          res[zone.id] = getZoneCode(zone);
          return res;
        }, {}),
        ...eventZones.reduce<{ [id: number]: string }>((res, { children }) => {
          children.forEach(({ id, name }) => (res[id] = getZoneCode({ name })));
          return res;
        }, {}),
      });
      setZonesMap({
        [france.id]: france,
        ...regions.reduce<{ [id: number]: { id: number; name: string } }>((res, zone) => {
          res[zone.id] = zone;
          return res;
        }, {}),
        ...eventZones.reduce<{ [id: number]: { id: number; name: string } }>(
          (res, { children }) => {
            children.forEach((child) => (res[child.id] = child));
            return res;
          },
          {},
        ),
      });
    } catch (err) {
      console.error(err);
    }

    loadingZones.current = false;
  }

  async function getRegisteredGroups() {
    try {
      const userChallenges = await UserService.getUserChallenges({
        page: 1,
        pageSize: 100,
        query: '{challenge{target_type, group}}',
        eventId: config === 'mdv' ? environment.mdvEventId : environment.eventId,
      });
      const groups: number[] = [];
      userChallenges.forEach(({ challenge: { group } }) => !!group && groups.push(group));
      setRegisteredGroups(groups);
    } catch (err) {
      console.error(err);
      setRegisteredGroups([]);
    }
  }

  return (
    <AppContext.Provider
      value={{
        event: { current: event, config },
        user: {
          current: currentUser,
          geogroups: userGeogroups,
          partners: userPartners,
          registeredGroups,
        },
        zones: { map: zonesMap, france, regions, epcisMap, codesMap: zonesCodesMap },
        actions: {
          getZones,
          setCurrentUser,
          setGeogroups: setUserGeogroups,
          setPartners: setUserPartners,
          setRegisteredGroups,
          setConfig,
        },
      }}
    >
      <MatomoProvider value={matomoInstance}>
        <ThemeProvider theme={theme}>
          <HelmetProvider context={helmetContext}>{initialized && <Router />}</HelmetProvider>
        </ThemeProvider>
      </MatomoProvider>
    </AppContext.Provider>
  );
}

export default App;
